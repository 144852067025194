import { render, staticRenderFns } from "./view.vue?vue&type=template&id=4cee4706&scoped=true"
import script from "./view.vue?vue&type=script&lang=js"
export * from "./view.vue?vue&type=script&lang=js"
import style0 from "./view.vue?vue&type=style&index=0&id=4cee4706&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cee4706",
  null
  
)

export default component.exports